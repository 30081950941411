<template>
  <label :for="name" class="base-select block">
    <span v-if="label || name" class="block">{{ label || name }}</span>
    <select :id="name" :value="value" :cy="cy" @change="handleSelect">
      <option v-if="isFetching" value="isFetching">fetching</option>
      <template v-else>
        <option value="placeholder" selected disabled>
          Selecteaza {{ name }}
        </option>
        <option v-for="(item, index) in options" :key="index" :value="item">
          {{ item }}
        </option>
      </template>
    </select>
    <span v-show="validate">{{ validationMsgs[rule] }}</span>
  </label>
</template>

<script setup>
  const props = defineProps({
    validate: Boolean,
    options: {
      type: Array,
      required: true
    },
    isFetching: {
      type: Boolean,
      default: false
    },
    name: String,
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    rule: {
      type: String,
      default: ''
    },
    cy: {
      type: String,
      default: ''
    }
  });

  const emit = defineEmits(['select']);

  const validationMsgs = {
    required: 'Acest camp este necesar'
  };

  function handleSelect (ev) {
    emit('select', { name: props.name, value: ev.target.value });
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .base-select {
    font-size: 1.6rem;
    select {
      height: 38px;
      margin-top: 6px;
      padding-left: 8px;
      font-size: 1.6rem;
      color: black;
      background-color: white;
      border: 1px solid lightgray;
    }
    > span:last-child {
      display: inline-block;
      margin-top: 4px;
      font-size: 1.4rem;
      color: $red;
    }
  }
</style>
