<template>
  <div class="table-data">
    <ul class="table-header flex">
      <li :class="prop" v-for="({ name, prop }, index) in header" :key="index">
        <b>{{ name }}</b>
      </li>
    </ul>

    <p v-if="data.length === 0" class="m-b-16">There are no tasks today</p>

    <ul
        v-else
      class="table-body flex"
      cy="table-body"
      v-for="(item, idx) in data"
      :key="idx"
    >
      <li
        :class="prop"
        class="m-b-16 pointer"
        v-for="({ prop }, index) in header"
        :key="index"
        @click="handleSelect(item)"
      >
        <input
          class="pointer"
          v-show="prop === 'status'"
          type="checkbox"
          :value="item[prop]"
        />
        <div v-show="prop === 'title'">
          <h3 class="relative">
            <span class="index-counter absolute"> {{ idx + 1 }}. </span>
            <b>{{ item.title }}</b>
          </h3>
          <p class="task-description m-t-4">{{ item.description }}</p>
        </div>

        <span v-if="prop === 'date'">
          {{ item.date && format(new Date(item.date), "dd MMM.") }}
        </span>

        <span v-show="prop === 'energy'">{{ item.energy }}%</span>

        <span v-show="prop === 'time'"
          >{{ item.timeValue.hours
          }}{{
            item.timeValue.minutes === 0 ? "" : `:${item.timeValue.minutes}`
          }}
          h</span
        >

        <span v-show="prop === 'type'" :class="`${item.type}-label`">{{
          item.type
        }}</span>

        <div v-if="prop === 'ddl'">
          {{ item.ddl && format(new Date(item.ddl), "dd MMM.") }}
        </div>

        <div v-if="prop === 'start'">
          {{
            item.start.hours
              ? `${item.start.hours}:${item.start.minutes} - ${item.end.hours}:${item.end.minutes} ${item.start.dayTime}`
              : ""
          }}
        </div>

        <span v-show="prop === 'appetite'">{{ item.appetite }}/10</span>
      </li>
    </ul>
  </div>
</template>

<script setup>
  import { format } from 'date-fns';

  const props = defineProps({
    name: String,
    header: Array,
    data: Array
  });

  const emits = defineEmits(['select']);

  function handleSelect (item) {
    emits('select', { name: props.name, data: item });
  }
</script>

<style lang="scss">
  .table-data {
    @media only screen and (min-width: 0) {
      padding: 0 24px;
      .table-header {
        padding-bottom: 8px;
        margin-bottom: 16px;
        border-bottom: 1px solid lightgray;
        li {
          color: gray;
        }
      }
      h3 b {
        font-size: 2rem;
      }
      .title {
        width: 400px;
      }
      .date,
      .energy,
      .time,
      .start,
      .ddl,
      .type {
        width: 100px;
      }
    }

    @media only screen and (min-width: 1024px) {
    }
  }
</style>
