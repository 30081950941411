import api from './api';
import axios from 'axios';

export const state = function () {
  return {
    data: {
      name: '',
      phone: ''
    },
    reqState: 'idle'
  };
};

export const mutations = {
  SET_REQ_STATE (state, value) {
    state.reqState = value;
  },
  SET_PROFILE (state, data) {
    state.data = data;
  },
  UPDATE_PROFILE_FIELD (state, {name, value}) {
    state.data[name] = value;
  },
};

export const actions = {
  async get_profile ({ commit }) {
    try {
      const { data } = await axios(api.profile);
      commit('SET_PROFILE', data);
    } catch (err) {}
  },
  async update_profile ({ state, commit }) {
    try {
      commit('SET_REQ_STATE', 'fetching')
      await axios.put(api.profile, state.data);
      commit('SET_REQ_STATE', 'saved')
      setTimeout(() => {
        commit('SET_REQ_STATE', 'idle')
      }, 3000)
    } catch (err) {}
  }
};
