<template>
  <div class="editor-actions flex">
    <button class="cancel-btn m-left-auto" cy="cancel-btn"
            type="button"
            @click="handleAction('cancel')">
      Cancel
    </button>
    <button
      v-show="deleteBtn"
      class="cancel-btn"
      cy="delete-btn"
      type="button"
      @click="handleAction('delete')"
    >
      Delete
    </button>
    <button class="save-btn" cy="save-btn"
            type="button"
            :disabled="saveDisabled"
            @click="handleAction('save')">
      {{ saveBtnText || "Save" }}
    </button>
  </div>
</template>

<script setup>
  const props = defineProps({
    deleteBtn: Boolean,
    saveDisabled: Boolean,
    saveBtnText: String
  });

  const emits = defineEmits(['select']);

  function handleAction (action) {
    emits('select', { action });
  }
</script>

<style lang="scss">
  @import "../styles/vars";
  .editor-actions {
    @media only screen and (min-width: 0) {
      button[disabled] {
        opacity: 0.5;
        cursor: default;
      }
    }
  }
</style>
