<template>
  <ul class="menu-tabs" cy="menu-tabs">
    <li
      class="pointer"
      :class="{ 'selected-tab': selectedTab === key, 'no-print': true }"
      v-for="({ name, key }, index) in list"
      :key="key"
      @click="handleSelect(key)"
    >
      {{ name }}
    </li>
  </ul>
</template>

<script setup>
  import { ref } from 'vue';

  const props = defineProps({
    name: String,
    selected: String,
    list: Array
  });

  const emits = defineEmits(['select']);
  const selectedTab = ref(props.selected);

  function handleSelect (key) {
    selectedTab.value = key;
    emits('select', { name: props.name, key });
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .menu-tabs {
    @media only screen and (min-width: 0) {
      margin: 16px 0;
      display: inline-flex;
      flex-wrap: wrap;
      li {
        padding: 4px 12px;
        border: 1px solid lightgray;
        &:not(:last-child) {
          border-right: 1px solid lightgray;
        }
        &:not(.selected-tab):hover {
          background-color: #efefef;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
    }

    .selected-tab {
      background-color: $green;
      color: white;
    }
  }
</style>
