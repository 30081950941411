<template>
  <div class="base-input" :cy="cy || name" :class="`${name}-input`">
    <label :for="name">
      <span>{{ label || name }}</span>
      <span v-if="rule === 'required'">*</span>
    </label>
    <textarea
      v-if="type === 'textarea'"
      :id="name"
      autocapitalize="off"
      :value="value"
      :disabled="disabled"
      @input="handleInput"
      :placeholder="placeholder || label || name"
    />
    <input
      v-else
      :id="name"
      autocapitalize="off"
      :type="type || 'text'"
      :value="value"
      :disabled="disabled"
      @input="handleInput"
      :placeholder="placeholder || label || name"
    />
    <span v-show="validate">{{ validationMsgs[rule] }}</span>
  </div>
</template>

<script setup>
  import { validationMsgs } from '@/store/data';

  const props = defineProps({
    name: String,
    cy: String,
    label: String,
    type: String,
    placeholder: String,
    value: [String, Number],
    disabled: Boolean,
    validate: Boolean,
    rule: String
  });

  const emits = defineEmits(['input']);

  function handleInput (ev) {
    emits('input', { name: props.name, value: ev.target.value });
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .base-input {
    @media only screen and (min-width: 0) {
      label {
        margin-bottom: 6px;
        display: block;
        font-size: 1.6rem;
      }
      > span {
        margin-top: 4px;
        margin-bottom: 14px;
        display: inline-block;
        color: $red;
        font-size: 1.4rem;
      }
      input,
      textarea {
        padding: 8px 0 8px 12px;
        font-size: 1.8rem;
        border: 1px solid lightgray;
      }
      textarea {
        width: 100%;
        min-height: 80px;
        resize: vertical;
      }
    }
  }
</style>
