<template>
  <div class="file-upload relative">
    <label class="pointer flex-center relative wh100" :for="name">
      <span>Upload file</span>
      <input :id="name" class="absolute" type="file" @change="handleFile" />
    </label>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  const props = defineProps({
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    }
  });

  const emit = defineEmits(['upload', 'remove']);

  const preview = ref(!!props.id && !!props.image);

  function handleFile (input) {
    preview.value = true;
    emit('upload', { name: props.name, file: input.target.files[0] });
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .file-upload {
    width: 120px;
    label {
      padding: 4px;
      border-radius: 4px;
      background-color: $light-green;
    }
    input {
      visibility: hidden;
      width: 0;
      height: 0.1px;
    }
    figure {
      overflow: hidden;
      border: 1px solid gray;
      border-radius: 4px;
    }
    > button {
      top: -14px;
      right: -14px;
      z-index: 9;
      background: none;
    }
  }
</style>
