import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import BaseModal from '@/components/BaseModal';
import BaseInput from '@/components/BaseInput';
import BaseFileUpload from '@/components/BaseFileUpload';
import BaseSelect from '@/components/BaseSelect';
import BaseChips from '@/components/BaseChips';
import TableData from '@/components/TableData';
import DaysRow from '@/components/DaysRow';
import MenuNav from '@/components/MenuNav';
import MenuTabs from '@/components/MenuTabs';
import EditIcon from '@/components/EditIcon';
import AddBtn from '@/components/AddBtn';
import BaseCheckbox from '@/components/BaseCheckbox';
import EditorActions from '@/components/EditorActions';
import BaseToggle from '@/components/BaseToggle.vue';
import BaseSearch from '@/components/BaseSearch.vue';
import Datepicker from '@vuepic/vue-datepicker';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import './styles/helper-classes.scss';
import './styles/typography.scss';
import './styles/grid.scss';
import './styles/base.scss';
import '@vuepic/vue-datepicker/dist/main.css';
import BaseEditor from "@/components/BaseEditor.vue";
import BaseRadioGroup from "@/components/BaseRadioGroup.vue";

const app = createApp(App);

app
  .component('BaseModal', BaseModal)
  .component('BaseInput', BaseInput)
  .component('BaseFileUpload', BaseFileUpload)
  .component('BaseSelect', BaseSelect)
  .component('BaseChips', BaseChips)
  .component('TableData', TableData)
  .component('DaysRow', DaysRow)
  .component('Datepicker', Datepicker)
  .component('MenuNav', MenuNav)
  .component('MenuTabs', MenuTabs)
  .component('EditIcon', EditIcon)
  .component('QuillEditor', QuillEditor)
  .component('EditorActions', EditorActions)
  .component('AddBtn', AddBtn)
  .component('BaseCheckbox', BaseCheckbox)
  .component('BaseToggle', BaseToggle)
  .component('BaseSearch', BaseSearch)
  .component('BaseEditor', BaseEditor)
  .component('BaseRadioGroup', BaseRadioGroup)
  .use(store)
  .use(router)

app.mount('#app');
