<template>
  <div class="base-toggle flex align-center no-print"
       :cy="cy || name || `base-toggle`">
    <span>{{ label || name }}&nbsp;&nbsp;</span>
    <label class="relative pointer inline-block"
           :for="id || name">
      <span class="absolute" :class="{'checked': isChecked}" />
      <input class="absolute"
             type="checkbox"
             :checked="value"
             @change="handleChange"
             :id="id || name" />
    </label>
  </div>
</template>

<script setup>
  import {ref} from "vue";

  const props = defineProps({
    cy: String,
    name: String,
    label: String,
    id: String,
    value: {
      type: Boolean,
      default: false
    }
  })

  const isChecked = ref(props.value)
  const emits = defineEmits(['change'])

  function handleChange (ev) {
    isChecked.value = ev.target.checked
    emits('change', {name: props.name, value: isChecked.value})
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .base-toggle {
    @media only screen and (min-width: 0) {
      label {
        width: 50px;
        height: 26px;
        background-color: white;
        border-radius: 24px;
        border: 1px solid lightgray;
        input {
          visibility: hidden;
          width: 0;
          height: 0.1px;
        }
        span {
          width: 20px;
          height: 20px;
          top: 2px;
          left: 2px;
          display: block;
          border-radius: 50%;
          background-color: gray;
          transition: transform 0.2s ease-in-out;
          &.checked {
            background-color: $soft-green;
            transform: translateX(116%);
          }
        }
      }
    }
  }
</style>

