<template>
  <ul class="days-bar flex" cy="days-row">
    <li
      v-for="({ name, number }, index) in daysOrdered"
      :key="index"
      class="flex-center"
      @click="handleSelect({name, number})"
      :class="{ 'selected-day': number === filters.day }"
    >
      <span>{{ windowWidth < 1024 ? name.charAt(0) : name }}</span><br>
      <span></span>
    </li>
  </ul>
</template>

<script setup>
import {days, getDaysInCurrentMonth} from '@/store/data';
  import { computed } from 'vue';
  import { useStore } from 'vuex';

  const emits = defineEmits(['select']);
  const windowWidth = window.innerWidth;

  const { state, dispatch, commit } = useStore();

  const filters = computed(() => state.tasks.filters);
  const daysOrdered = computed(() => days);

  function handleSelect ({name, number}) {
    const currentDate = new Date().getDate();
    const currentDay = +new Date().getDay();
    const date = number + currentDate - currentDay
    const value = date > getDaysInCurrentMonth() ? date - getDaysInCurrentMonth() : date
    emits('select', {dayName: name, dayNumber: number, date: value});
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .days-bar {
    @media only screen and (min-width: 0) {
      flex-wrap: wrap;
      border: 1px solid $soft-green;
      background-color: white;
      border-radius: 24px;
      li {
        margin: 4px;
        border-radius: 50%;
        cursor: pointer;
        span {
          color: $dark-green;
        }
        &.selected-day {
          background-color: $soft-green;
          span {
            color: white;
            font-weight: 500;
          }
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      li {
        padding: 6px 12px;
        border-radius: 24px;
      }
    }

    @media only screen and (max-width: 1024px) {
      li {
        width: 30px;
        height: 30px;
      }
    }
  }
</style>
