import axios from 'axios';
import api from './api';
import router from '@/router';

export const state = {
  user: { role: '', today: {}, full_name: undefined },
  wrongCredentials: false,
  accountCreated: false,
  accountActivated: false
};

export const mutations = {
  SET_USER (state, user) {
    state.user = user;
    state.wrongCredentials = false;
  },
  SET_AUTH_STATE (state, { key, value }) {
    state[key] = value;
  },
  UPDATE_GOAL (state, value) {
    state.user.today.title = value;
  },
  RESET_ACCOUNT_CREATED (state) {
    state.accountCreated = false;
  },
  RESET_ACCOUNT_ACTIVATED (state) {
    state.accountActivated = false;
  }
};

export const actions = {
  async login ({ state, commit }, user) {
    try {
      const { data } = await axios.post(api.login, user);
      commit('SET_AUTH_STATE', { key: 'credentials', value: {} });
      commit('SET_USER', data);
      await router.push('/');
    } catch (err) {
      console.log(err);
      commit('SET_AUTH_STATE', { key: 'wrongCredentials', value: true });
    }
  },
  async logout ({ commit }) {
    try {
      await axios.delete(api.logout);
      commit('SET_USER', {});
      await router.push('/login');
    } catch (err) {
      console.log(err);
    }
  },
  async get_user ({ commit, dispatch }, next) {
    try {
      const { data: user } = await axios.get(api.checkLogin);
      if (user.name) {
        commit('SET_USER', user);
        next();
      }
    } catch (e) {
      next('/login');
    }
  },
  async signup ({ commit }, user) {
    try {
      const { data } = await axios.post(api.signup, user);
      console.log(user);
      commit('SET_AUTH_STATE', { key: 'accountCreated', value: true });
    } catch (err) {
      console.log(err);
    }
  },
  async verifySignup ({ commit }, { id, email }) {
    try {
      const { data } = await axios.post(`${api.verifySignup}/${id}/${email}`);
      console.log(data);
      commit('SET_AUTH_STATE', { key: 'accountActivated', value: true });
    } catch (err) {
      console.log(err);
    }
  },
  async delete_account ({ commit }, user) {
    try {
      const { data } = await axios.delete(api.delete_account);
      commit('SET_USER', {});
    } catch (err) {
      console.log(err);
    }
  }
};

export const getters = {
  userName: function (state) {
    return state.user?.full_name;
  }
};
