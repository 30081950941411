import axios from 'axios';
import api from './api';
import router from '@/router';

export const state = {
  weather: null
};

export const mutations = {
  SET_WEATHER (state, data) {
    state.weather = data;
  }
};

export const actions = {
  async get_weather ({ state, commit }, location) {
    try {
      const { data } = await axios.post(api.weather, location);
      commit('SET_WEATHER', data);
    } catch (err) {
      console.log(err);
    }
  }
};
