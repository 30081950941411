import axios from 'axios';
import api from '@/store/modules/api';

export const state = function () {
  return {
    data: {
      tasks: {
        types: []
      },
      reminders: {
        types: []
      }
    }
  };
};

export const mutations = {
  SET_SETTINGS_STATE (state, { key, value }) {
    state[key] = value;
  },
  ADD_SETTINGS_TYPE (state, { category, value }) {
    state.data[category].types.push(value);
  },
  SET_SETTINGS_TYPES (state, { category, types }) {
    state.data[category].types = types;
  }
};

export const actions = {
  async get_settings ({ commit }) {
    try {
      const { data } = await axios.get(`${api.profile}/settings`);
      delete data._id;
      commit('SET_SETTINGS_STATE', { key: 'data', value: data });
    } catch (err) {}
  },
  async update_settings_type ({ state, commit }, { category, value }) {
    try {
      console.log(category);
      console.log(value);
      await axios.put(`${api.profile}/settings/${category}`, {
        types: [...state.data[category].types, value]
      });
      commit('ADD_SETTINGS_TYPE', { category, value });
    } catch (err) {
      console.log(err);
    }
  },
  async delete_settings_type ({ state, commit }, { category, value }) {
    try {
      const types = state.settings[category].types.filter(
        (val) => val !== value
      );
      await axios.put(`${api.profile}/settings/${category}`, { types });
      commit('SET_SETTINGS_TYPES', { category, types });
    } catch (err) {
      console.log(err);
    }
  }
};
