import axios from 'axios';
import api from '@/store/modules/api';

export const state = function () {
  return {
    data: []
  };
};

export const mutations = {
  SET_BOOKS_STATE (state, { key, value }) {
    state[key] = value;
  },
  ADD_BOOK (state, data) {
    state.data.push(data);
  },
  UPDATE_BOOK (state, data) {
    const index = state.data.findIndex((o) => o._id === data._id);
    state.data[index] = data;
  },
  REMOVE_BOOK (state, id) {
    const index = state.data.findIndex((o) => o._id === id);
    state.data.splice(index, 1);
  }
};

export const actions = {
  async get_books ({ commit }) {
    try {
      const { data } = await axios(api.books);
      commit('SET_BOOKS_STATE', { key: 'data', value: data });
    } catch (err) {}
  },
  async create_book ({ commit }, idea) {
    try {
      const { data } = await axios.post(api.books, idea);
      commit('ADD_BOOK', data);
      commit('SET_MODAL', false, { root: false });
    } catch (err) {}
  },
  async update_book ({ commit }, idea) {
    try {
      const url = `${api.books}/${idea._id}`;
      const { data } = await axios.put(url, idea);
      commit('UPDATE_BOOK', idea);
    } catch (err) {}
  },
  async delete_book ({ commit }, id) {
    try {
      const url = `${api.books}/${id}`;
      const { data } = await axios.delete(url);
      commit('REMOVE_BOOK', id);
    } catch (err) {}
  }
};
