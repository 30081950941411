<template>
  <div class="base-radio-group">
    <label :for="id" v-for="({id, label}, index) in list"
           :key="index"
           class="pointer m-r-8">
      <input :name="name"
             :id="id"
             type="radio"
             :checked="label === selected"
             @change="handleChange(label)" />
      <span>{{ label || name }}</span>
    </label>
  </div>
</template>

<script setup>
  const props = defineProps({
    name: String,
    selected: String,
    list: Array
  })

  const emits = defineEmits(['select'])

  function handleChange (value) {
    emits('select', {name: props.name, value})
  }
</script>

<style lang="scss">
  .base-radio-group {
    @media only screen and (min-width: 0) {
      label {
        display: block;
      }
    }

    @media only screen and (min-width: 1024px) {
      label {
        display: inline-block;
      }
    }
  }
</style>