<template>
  <QuillEditor
      toolbar="essential"
      theme="snow"
      v-model:content="model"
      contentType="html"
      @keyup="handleTextChange"
  />
</template>

<script setup>
  import {ref} from "vue";
  import {useRouter, onBeforeRouteLeave} from "vue-router";

  const router = useRouter()

  const props = defineProps({
    name: String,
    value: String
  })
  const emits = defineEmits(['type'])
  const model = ref(props.value)
  const initialValue = props.value
  function handleTextChange () {
    emits('type', {name: props.name, value: model.value})
  }

  onBeforeRouteLeave((to, from, next) => {
    if (initialValue !== model.value) {
      window.alert('Nu uita sa salvezi datele!')
      next(false);
    } else {
      next();
    }
  })
</script>

<style lang="scss">

</style>