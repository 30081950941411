export default {
  login: '/api/auth/login',
  logout: '/api/auth/logout',
  checkLogin: '/api/auth/login',
  signup: '/api/auth/signup',
  verifySignup: '/api/auth/verify-signup',
  tasks: '/api/tasks',
  reminders: '/api/reminders',
  profile: '/api/profile',
  projects: '/api/projects',
  ideas: '/api/ideas',
  models: '/api/models',
  studies: '/api/studies',
  weather: '/api/weather',
  books: '/api/books',
};
