import axios from 'axios';
import api from '@/store/modules/api';

export const state = function () {
  return {
    data: []
  };
};

export const mutations = {
  SET_STUDIES_STATE (state, { key, value }) {
    state[key] = value;
  },
  ADD_STUDY (state, data) {
    state.data.push(data);
  },
  UPDATE_STUDY (state, data) {
    const index = state.data.findIndex((o) => o._id === data._id);
    state.data[index] = data;
  },
  REMOVE_STUDY (state, id) {
    const index = state.data.findIndex((o) => o._id === id);
    state.data.splice(index, 1);
  },
  ADD_NOTE_TO_STUDY(state, { id, note }) {
    const studyIndex = state.data.findIndex(study => study._id === id);
    if (studyIndex !== -1) {
      state.data[studyIndex].notes.push(note);
    }
  },

  EDIT_STUDY_NOTE(state, { id, note }) {
    const studyIndex = state.data.findIndex(study => study._id === id);
    if (studyIndex !== -1) {
      const noteIndex = state.data[studyIndex].notes.findIndex(n => n._id === note._id);
      if (noteIndex !== -1) {
        state.data[studyIndex].notes[noteIndex] = note;
      }
    }
  },

  DELETE_STUDY_NOTE(state, { id, noteId }) {
    const studyIndex = state.data.findIndex(study => study._id === id);
    if (studyIndex !== -1) {
      const notes = state.data[studyIndex].notes;
      const filteredNotes = notes.filter(note => note._id !== noteId);
      state.data[studyIndex].notes = filteredNotes;
    }
  },
};

export const actions = {
  async get_studies ({ commit }) {
    try {
      const { data } = await axios(api.studies);
      console.log(data)
      commit('SET_STUDIES_STATE', { key: 'data', value: data });
    } catch (err) {}
  },
  async create_study ({ commit }, study) {
    try {
      const { data } = await axios.post(api.studies, study);
      commit('ADD_STUDY', data);
    } catch (err) {}
  },
  async update_study ({ commit }, study) {
    try {
      const url = `${api.studies}/${study._id}`;
      const { data } = await axios.put(url, study);
      commit('UPDATE_STUDY', study);
    } catch (err) {}
  },
  async delete_study ({ commit }, id) {
    try {
      const url = `${api.studies}/${id}`;
      const { data } = await axios.delete(url);
      commit('REMOVE_STUDY', id);
    } catch (err) {}
  },
  async add_note_to_study({ commit }, { id, note }) {
    try {
      console.log(id, note)
      const { data } = await axios.post(`${api.studies}/${id}/notes`, note);
      commit('ADD_NOTE_TO_STUDY', { id, note: data });
    } catch (err) {}
  },

  async edit_study_note({ commit }, { id, note }) {
    try {
      const { data } = await axios.put(`${api.studies}/${id}/notes`, note);
      commit('EDIT_STUDY_NOTE', { id, note: data });
    } catch (err) {}
  },

  async delete_study_note({ commit }, { id, noteId }) {
    try {
      await axios.delete(`${api.studies}/${id}/notes`, { data: { _id: noteId } });
      commit('DELETE_STUDY_NOTE', { id, noteId });
    } catch (err) {}
  },
};
