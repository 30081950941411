import axios from 'axios';
import api from './api';
import { projectMenuTabs } from '@/store/data';

function makeProject () {
  return {
    id: '',
    createdBy: '',
    member_type: '',
    details: { title: 'loading project...', description: '' },
    members: [],
    notes: [],
    activities: [],
    meetings: [],
    participants: [],
    couchParticipants: [],
    crms: [],
    links: [],
    decisions: [],
    strategies: [],
    member: {},
    strategy: {},
    planning: {
      steps: []
    },
    archived: false,
    settings_tabs: [],
    mvp: { description: '' },
    tech: { description: '' }
  };
}

export const state = function () {
  return {
    data: [],
    selectedProject: {},
    project: makeProject(),
    isEdit: false,
    filters: {
      hideChecked: localStorage.getItem('hideChecked') === 'true',
      myTasks: localStorage.getItem('myTasks') === 'true',
      searchProject: '',
      searchCrm: '',
      archived: false
    }
  };
};

export const mutations = {
  SET_FILTERS (state, { name, value }) {
    state.filters[name] = value;
  },
  SET_PROJECTS_STATE (state, { key, value }) {
    state[key] = value;
  },
  SET_SELECTED_PROJECT (state, value) {
    state.selectedProject = value;
  },
  ADD_PROJECT (state, project) {
    state.data.push(project);
  },
  UPDATE_PROJECT_SECTION (state, { id, section, action, value }) {
    let index, taskIndex;
    switch (action) {
      case 'update-section':
        state.project[value.section] = value.value;
        break;
      case 'update-details':
        state.project[section] = value;
        break;
      case 'add-member':
        state.project[section].push(value);
        break;
      case 'update-member':
        index = state.project.members.findIndex((o) => o._id === value._id);
        state.project.members[index] = value;
        break;
      case 'delete-member':
        index = state.project.members.findIndex((o) => o._id === value._id);
        state.project.members.splice(index, 1);
        break;
      case 'create-step':
        state.project.planning.steps.push(value);
        break;
      case 'update-step':
        index = state.project.planning.steps.findIndex(
          (o) => o._id === value._id
        );
        state.project.planning.steps[index] = value;
        break;
      case 'delete-step':
        index = state.project.planning.steps.findIndex(
          (o) => o._id === value._id
        );
        state.project.planning.steps.splice(index, 1);
        break;
      case 'create-task':
        index = state.project.planning.steps.findIndex(
          (o) => o._id === value.step_id
        );
        delete value.step_id;
        if (!state.project.planning.steps[index].tasks) {
          state.project.planning.steps[index].tasks = [];
        }
        state.project.planning.steps[index].tasks.push(value);
        break;
      case 'update-task':
        index = state.project.planning.steps.findIndex(
          (o) => o._id === value.step_id
        );
        taskIndex = state.project.planning.steps[index].tasks.findIndex(
          (o) => o._id === value._id
        );
        delete value.step_id;
        state.project.planning.steps[index].tasks[taskIndex] = value;
        break;
      case 'delete-task':
        index = state.project.planning.steps.findIndex(
          (o) => o._id === value.step_id
        );
        taskIndex = state.project.planning.steps[index].tasks.findIndex(
          (o) => o._id === value._id
        );
        state.project.planning.steps[index].tasks.splice(taskIndex, 1);
        break;
      case 'create-decision':
        state.project.decisions.push(value);
        break;
      case 'update-decision':
        index = state.project.decisions.findIndex((o) => o._id === value._id);
        state.project.decisions[index] = value;
        break;
      case 'delete-decision':
        index = state.project.decisions.findIndex((o) => o._id === value._id);
        state.project.decisions.splice(index, 1);
        break;
      case 'create-crm':
        state.project.crms.push(value);
        break;
      case 'update-crm':
        index = state.project.crms.findIndex((o) => o._id === value._id);
        state.project.crms[index] = value;
        break;
      case 'delete-crm':
        index = state.project.crms.findIndex((o) => o._id === value._id);
        state.project.crms.splice(index, 1);
        break;
      case 'create-participant':
        state.project.participants.push(value);
        break;
      case 'update-participant':
        index = state.project.participants.findIndex((o) => o._id === value._id);
        state.project.participants[index] = value;
        break;
      case 'delete-participant':
        index = state.project.participants.findIndex((o) => o._id === value._id);
        state.project.participants.splice(index, 1);
        break;
      case 'create-strategy':
        if (!state.project.strategies) {
          state.project.strategies = [];
        }
        state.project.strategies.push(value);
        break;
      case 'update-strategy':
        index = state.project.strategies.findIndex((o) => o._id === value._id);
        state.project.strategies[index] = value;
        break;
      case 'delete-strategy':
        index = state.project.strategies.findIndex((o) => o._id === value._id);
        state.project.strategies.splice(index, 1);
        break;
      case 'create-note':
        state.project.notes.push(value);
        break;
      case 'update-note':
        index = state.project.notes.findIndex((o) => o._id === value._id);
        state.project.notes[index] = value;
        break;
      case 'delete-note':
        index = state.project.notes.findIndex((o) => o._id === value._id);
        state.project.notes.splice(index, 1);
        break;
      case 'create-activity':
        state.project.activities.push(value);
        break;
      case 'update-activity':
        index = state.project.activities.findIndex((o) => o._id === value._id);
        state.project.activities[index] = value;
        break;
      case 'delete-activity':
        index = state.project.activities.findIndex((o) => o._id === value._id);
        state.project.activities.splice(index, 1);
        break;
      case 'create-link':
        state.project.links.push(value);
        break;
      case 'create-meeting':
        state.project.meetings.push(value);
        break;
      case 'update-meeting':
        index = state.project.meetings.findIndex((o) => o._id === value._id);
        state.project.meetings[index] = value;
        break;
      case 'delete-meeting':
        index = state.project.meetings.findIndex((o) => o._id === value._id);
        state.project.meetings.splice(index, 1);
        break;
      case 'update-link':
        index = state.project.links.findIndex((o) => o._id === value._id);
        state.project.links[index] = value;
        break;
      case 'delete-link':
        index = state.project.links.findIndex((o) => o._id === value._id);
        state.project.links.splice(index, 1);
        break;
      case 'update-steps-order':
        // value is array with the new order ids
        const arr = Object.keys(value).map((key) => value[key]);
        const rearrangedArray = state.project.planning.steps.sort((a, b) => {
          const aIndex = arr.indexOf(a._id);
          const bIndex = arr.indexOf(b._id);
          return aIndex - bIndex;
        });
        state.project.planning.steps = [...rearrangedArray];
        break;
      case 'archive-project':
        break;
      case 'restore-project':
        state.project.archived = false;
        break;
    }
  },
  TOGGLE_STEP_DETAILS (state, stepIndex) {
    state.project.planning.steps[stepIndex].detailsVisible =
      !state.project.planning.steps[stepIndex].detailsVisible;
  },
  ADD_SETTINGS_TAB (state, tab) {
    state.project.settings.push(tab);
  },
  RESET_PROJECT (state) {
    state.project = makeProject();
  },
  UPDATE_DOCS (state, filename) {
    const index = state.project.docs.findIndex((val) => val === filename);
    index > -1
      ? state.project.docs.splice(index, 1)
      : state.project.docs.push(filename);
  },
  ADD_TASK_FILE (state, formData) {
    const name = formData.get('file').name;
    const step_id = formData.get('step_id');
    const task_id = formData.get('task_id');
    const stepIndex = state.project.planning.steps.findIndex(
      (o) => o._id === step_id
    );
    const taskIndex = state.project.planning.steps[stepIndex].tasks.findIndex(
      (o) => o._id === task_id
    );
    state.project.planning.steps[stepIndex].tasks[taskIndex].docs.push(name);
  },
  DELETE_TASK_FILE (state, { step_id, task_id, filename }) {
    const stepIndex = state.project.planning.steps.findIndex(
      (o) => o._id === step_id
    );
    const taskIndex = state.project.planning.steps[stepIndex].tasks.findIndex(
      (o) => o._id === task_id
    );
    const docIndex = state.project.planning.steps[stepIndex].tasks[
      taskIndex
    ].docs.findIndex((val) => val === filename);
    state.project.planning.steps[stepIndex].tasks[taskIndex].docs.splice(
      docIndex,
      1
    );
  }
};

export const actions = {
  async get_projects ({ commit }) {
    try {
      const { data } = await axios(api.projects);
      commit('SET_PROJECTS_STATE', { key: 'data', value: data });
    } catch (err) {}
  },
  async create_project ({ commit }, payload) {
    try {
      const url = `${api.projects}?action=create-project`;
      const { data } = await axios.post(url, payload);
      commit('ADD_PROJECT', data);
      commit('SET_MODAL', false, { root: false });
    } catch (err) {
      commit('SET_MODAL', false, { root: false });
    }
  },
  async get_project ({ commit }, id) {
    try {
      commit('RESET_PROJECT');
      const url = `${api.projects}/${id}`;
      const { data } = await axios(url);
      if (!data.settings_tabs) {
        data.settings_tabs = [...projectMenuTabs];
      }
      commit('SET_PROJECTS_STATE', { key: 'project', value: data });
    } catch (err) {}
  },
  async update_project (
    { commit },
    { id, section, action, value = {}, clbk = () => {} }
  ) {
    try {
      const url = `${api.projects}/${id}?action=${action}`;
      const { data } = await axios.put(url, value);
      commit('UPDATE_PROJECT_SECTION', {
        id,
        section,
        action,
        value: { ...value, ...data }
      });
      clbk();
    } catch (err) {
      console.log(err);
    }
  },
  // not used yet
  async update_project_crm (
    { commit },
    { id, section, action, value = {}, clbk = () => {} }
  ) {
    try {
      const url = `${api.projects}/crm/${id}`;
      let data;
      switch (action) {
        case 'create-crm': {
          const response = await axios.post(url, value);
          data = response.data;
          break;
        }
        case 'update-crm': {
          const response = await axios.put(url, value);
          data = response.data;
          break;
        }
        case 'delete-crm': {
          const response = await axios.delete(`${url}?crm_id=${value._id}`);
          data = response.data;
          break;
        }
        default:
        // Handle the default case
      }
      commit('UPDATE_PROJECT_SECTION', {
        id,
        section,
        action,
        value: { ...value, ...data }
      });
      clbk();
    } catch (err) {
      console.log(err);
    }
  },
  async upload_doc ({ commit }, { id, formData, filename }) {
    try {
      const url = `${api.projects}/${id}/project-docs`;
      await axios.post(url, formData);
      commit('UPDATE_DOCS', filename);
    } catch (err) {}
  },
  async delete_doc ({ commit }, { id, filename }) {
    try {
      const url = `${api.projects}/${id}/project-docs`;
      await axios.delete(url, { data: { name: filename } });
      commit('UPDATE_DOCS', filename);
      commit('SET_MODAL', false, { root: false });
      commit('SET_STATE', { key: 'modalName', value: '' }, { root: false });
    } catch (err) {}
  },
  async delete_task_doc ({ commit }, { id, task_id, step_id, filename }) {
    try {
      const url = `${api.projects}/${id}/task-docs`;
      await axios.delete(url, { data: { task_id, step_id, name: filename } });
      commit('DELETE_TASK_FILE', { step_id, task_id, filename });
      commit('SET_MODAL', false, { root: false });
      commit('SET_STATE', { key: 'modalName', value: '' }, { root: false });
    } catch (err) {}
  },
  async upload_task_file ({ commit }, { id, formData }) {
    try {
      const url = `${api.projects}/${id}/task-docs`;
      const { data } = await axios.post(url, formData);
      commit('ADD_TASK_FILE', formData);
    } catch (err) {}
  },
  async get_participants ({ commit }) {
    try {
      const url = `https://my.c156.ro/api/registrations`;
      const { data } = await axios.get(url);
      commit('SET_PROJECTS_STATE', {key: 'couchParticipants', value: processStudentData(data)})
    } catch (err) {}
  }
};

function processStudentData(students) {
  // Helper function to convert name to uppercase and trim spaces
  function normalizeName(name) {
    return name.trim().toUpperCase();
  }

  // Initialize a map to keep track of processed students
  const studentMap = new Map();

  // Process each student in the array
  students.forEach(student => {
    const normalizedName = normalizeName(student.name);

    // Convert attachment names to objects
    const attachmentObjects = student.attachment_names.map((name, index) => ({
      _id: student._id,
      name: name
    }));

    if (!studentMap.has(normalizedName)) {
      // If the student is not already in the map, add them
      studentMap.set(normalizedName, {
        ...student,
        name: normalizedName,
        attachment_names: attachmentObjects
      });
    } else {
      // If the student is already in the map, merge the attachments
      const existingStudent = studentMap.get(normalizedName);
      const existingAttachments = existingStudent.attachment_names;
      const newAttachments = [...existingAttachments];

      attachmentObjects.forEach(attachment => {
        if (!existingAttachments.some(a => a.name === attachment.name)) {
          newAttachments.push(attachment);
        }
      });

      studentMap.set(normalizedName, {
        ...existingStudent,
        attachment_names: newAttachments
      });
    }
  });

  // Convert the map back to an array
  return Array.from(studentMap.values());
}

export const getters = {
  strategies: (state) => state.project.strategies.sort((a, b) => b.createdAt - a.createdAt),
  decisions: (state) => state.project.decisions.reverse(),
  projects: function (state) {
    let response = state.data.filter(o => !o.archived);
    if (state.filters.archived) {
      response = state.data.filter(o => o.archived === state.filters.archived);
    }
    if (!!state.filters.searchProject) {
      response = response.filter((o) =>
        o.title
          ?.toLowerCase()
          .includes(state.filters.searchProject?.toLowerCase())
      );
    }
    return response;
  },
  steps: function (state, getters, rootState) {
    const name = rootState.auth.user?.full_name;
    let results = [...state.project.planning.steps];
    if (state.filters.hideChecked) {
      results = results.map((item) => ({
        ...item,
        tasks: item.tasks?.filter((task) => task.status !== 'done')
      }));
    }
    if (state.filters.myTasks) {
      results = results.map((item) => ({
        ...item,
        tasks: item.tasks?.filter((task) => task.members.includes(name))
      }));
    }
    return results;
  },
  notes: function (state) {
    return state.project.notes.sort((a, b) => {
      return b.createdAt - a.createdAt;
    });
  },
  activities: function (state) {
    return state.project.activities.sort((a, b) => {
      return b.createdAt - a.createdAt;
    });
  },
  meetings: function (state) {
    if (!state.project.meetings) {
      state.project.meetings = [];
    }
    return state.project.meetings.sort((a, b) => {
      return b.createdAt - a.createdAt;
    });
  },
  crm: function (state) {
    if (!state.project.crms) {
      state.project.crms = [];
    }
    let results = [...state.project.crms];
    if (state.filters.searchCrm) {
      results = results.filter(({client}) => {
        return client.name?.toLowerCase().includes(state.filters.searchCrm.toLowerCase())
      });
    }
    return results.sort((a, b) => {
      return b.createdAt - a.createdAt;
    });
  },
  links: function (state) {
    return state.project.links.sort((a, b) => {
      return b.createdAt - a.createdAt;
    });
  },
  settingsTabs: function (state) {
    // const tabs = [...state.project.settings_tabs];
    // const arr = state.project.settings_tabs ? [...tabs] : [...projectMenuTabs];
    if (state.project.settings_tabs && state.project.settings_tabs.length) {
      return state.project.settings_tabs;
    } else {
      const arr = [...projectMenuTabs];
      arr.pop();
      return arr;
    }
  }
};
