<template>
  <label class="base-checkbox pointer" :for="name">
    <input
      class="m-r-8"
      :id="name"
      type="checkbox"
      :checked="checked"
      @change="handleCheck"
    />
    <span>{{ label || name }}</span>
  </label>
</template>

<script setup>
  const props = defineProps({
    name: String,
    label: String,
    checked: Boolean
  });

  const emits = defineEmits(['change']);

  function handleCheck (ev) {
    emits('change', { name: props.name, value: ev.target.checked });
  }
</script>

<style lang="scss">
  .base-checkbox {
    @media only screen and (min-width: 0) {
      span {
        font-size: 1.8rem;
      }
    }
  }
</style>
