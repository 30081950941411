<template>
  <button
    v-if="isEdit"
    :class="`add-btn ${className} no-print`"
    :cy="name"
    @click="handleClick"
  >
    {{ text }}
  </button>
</template>

<script setup>
  import { computed } from 'vue';
  import { useStore } from 'vuex';

  const { state, dispatch, commit } = useStore();
  const props = defineProps({
    name: String,
    text: String,
    className: String
  });
  const emits = defineEmits(['click']);
  const isEdit = computed(() => state.projects.isEdit);

  function handleClick () {
    emits('click', { name: props.name });
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .add-btn {
    @media only screen and (min-width: 0) {
      background-color: $light-green;
      color: black;
    }
  }
</style>
