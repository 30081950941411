import axios from 'axios';
import api from '@/store/modules/api';
import { formDataToObject } from '../data'

export const state = function () {
  return {
    data: []
  };
};

export const mutations = {
  SET_MODELS_STATE (state, { key, value }) {
    state[key] = value;
  },
  ADD_MODEL (state, data) {
    state.data.push(data);
  },
  UPDATE_MODEL (state, data) {
    const index = state.data.findIndex((o) => o._id === data._id);
    state.data[index] = data;
  },
  REMOVE_MODEL (state, id) {
    const index = state.data.findIndex((o) => o._id === id);
    state.data.splice(index, 1);
  }
};

export const actions = {
  async get_models ({ commit }) {
    try {
      const { data } = await axios(api.models);
      commit('SET_MODELS_STATE', { key: 'data', value: data });
    } catch (err) {}
  },
  async create_model ({ commit }, model) {
    try {
      const { data } = await axios.post(api.models, model);
      commit('ADD_MODEL', data);
    } catch (err) {}
  },
  async update_model ({ commit }, model) {
    try {
      const url = `${api.models}/${model.get('_id')}`;
      await axios.put(url, model);
      commit('UPDATE_MODEL', formDataToObject(model));
    } catch (err) {}
  },
  async delete_model ({ commit }, id) {
    try {
      const url = `${api.models}/${id}`;
      const { data } = await axios.delete(url);
      commit('REMOVE_MODEL', id);
    } catch (err) {}
  }
};
