import axios from 'axios';
import api from '@/store/modules/api';
import { getDaysInCurrentMonth } from '../data';

function makeTask () {
  return {
    energy: '0',
    realEnergy: '0',
    type: 'meeting',
    date: new Date().getTime(),
    timeValue: {
      hours: 0,
      minutes: 0
    },
    realTimeValue: {
      hours: 0,
      minutes: 0
    },
    start: {
      hours: '',
      minutes: '00',
      dayTime: 'PM'
    },
    end: {
      hours: '',
      minutes: '00',
      dayTime: 'PM'
    },
    ddl: '',
    appetite: '',
    meetingInPerson: false,
    status: 'progress',
    attendees: []
  };
}

function makeReminder () {
  return {
    title: '',
    type: '',
    target: '',
    when: '',
    why: ''
  };
}

export const state = function () {
  return {
    data: [],
    settings: {
      tasks: {
        types: ['meeting', 'coding']
      },
      reminders: {
        types: []
      }
    },
    reminders: [],
    selectedTask: makeTask(),
    selectedReminder: makeReminder(),
    doneTasks: [],
    filters: {
      day: new Date().getDay(),
      date: new Date().getDate()
    }
  };
};

export const mutations = {
  SET_TASKS (state, tasks) {
    state.data = tasks;
  },
  SET_TASKS_FILTERS (state, { key, value }) {
    state.filters[key] = value;
  },
  SET_STATE (state, { key, value }) {
    state[key] = value;
  },
  RESET_SELECTED_TASK (state) {
    state.selectedTask = makeTask();
    state.selectedReminder = makeReminder();
  },
  SET_SELECTED_TASK (state, task) {
    state.selectedTask = task;
  },
  SET_SELECTED_REMINDER (state, reminder) {
    state.selectedReminder = reminder;
  },
  UPDATE_TASK (state, data) {
    const index = state.data.findIndex((o) => o._id === data._id);
    state.data[index] = data;
  },
  UPDATE_REMINDER (state, data) {
    const index = state.data.findIndex((o) => o._id === data._id);
    state.data[index] = data;
  },
  DELETE_TASK (state, id) {
    const index = state.data.findIndex((o) => o._id === id);
    index !== -1 && state.data.splice(index, 1);
  },
  DELETE_REMINDER (state, id) {
    const index = state.reminders.findIndex((o) => o._id === id);
    index !== -1 && state.reminders.splice(index, 1);
  },
  UPDATE_TASK_FIELD (state, { name, value }) {
    state.selectedTask[name] = value;
  },
  UPDATE_TASK_EST_TIME (state, { name, value }) {
    state.selectedTask.timeValue[name] = value;
  },
  UPDATE_TASK_REAL_TIME (state, { name, value }) {
    state.selectedTask.realTimeValue[name] = value;
  },
  UPDATE_TASK_MEETING_TIME (state, { key, value }) {
    state.selectedTask[key] = value;
    if (key === 'start' && !state.selectedTask.end.hours) {
      state.selectedTask.end.dayTime = value.dayTime;
      state.selectedTask.end.hours = value.hours;
      state.selectedTask.end.minutes = '0';
    }
  },
  UPDATE_REMINDER_FIELD (state, { name, value }) {
    state.selectedReminder[name] = value;
  },
  ADD_NEW_TASK (state, task) {
    const index = state.data.findIndex((o) => o._id === task._id);
    index === -1 && state.data.push(task);
  },
  ADD_NEW_REMINDER (state, reminder) {
    const index = state.reminders.findIndex((o) => o._id === reminder._id);
    index === -1 && state.reminders.push(reminder);
  },
  SET_REMINDER_STATUS (state, status) {
    state.selectedReminder.status = status;
  },
  UPDATE_REMINDER_EVENT (state, data) {
    const index = state.reminders.findIndex((o) => o._id === data._id);
    state.reminders[index] = data;
  },
  SET_DONE_TASKS (state, tasks) {
    state.doneTasks = tasks;
  }
};

export const actions = {
  async get_tasks ({ state, commit }) {
    try {
      const { data } = await axios.get(api.tasks);
      commit('SET_TASKS', data);
    } catch (err) {}
  },
  async get_done_tasks ({ state, commit }) {
    try {
      const { data } = await axios.get(`${api.tasks}/done`);
      commit('SET_DONE_TASKS', data);
    } catch (err) {}
  },
  async get_reminders ({ state, commit }) {
    try {
      const { data } = await axios.get(api.reminders);
      commit('SET_STATE', { key: 'reminders', value: data });
    } catch (err) {}
  },
  async create_task ({ state, commit }) {
    try {
      const { data } = await axios.post(api.tasks, state.selectedTask);
      commit('ADD_NEW_TASK', data);
      commit('SET_MODAL', false, { root: true });
    } catch (err) {}
  },
  async create_reminder ({ state, commit }) {
    try {
      const { data } = await axios.post(api.reminders, state.selectedReminder);
      commit('ADD_NEW_REMINDER', data);
      commit('SET_MODAL', false, { root: true });
    } catch (err) {}
  },
  async update_task ({ state, commit }) {
    try {
      const { data } = await axios.put(
        `${api.tasks}/${state.selectedTask._id}`,
        state.selectedTask
      );
      commit('UPDATE_TASK', data);
      commit('SET_SELECTED_TASK', {});
      commit('SET_MODAL', false, { root: true });
    } catch (err) {
      console.log(err);
    }
  },
  async update_reminder ({ state, commit }) {
    try {
      const { data } = await axios.put(
        `${api.reminders}/${state.selectedReminder._id}`,
        state.selectedReminder
      );
      commit('UPDATE_REMINDER', data);
      commit('SET_SELECTED_REMINDER', {});
      commit('SET_MODAL', false, { root: true });
    } catch (err) {
      console.log(err);
    }
  },
  async delete_task ({ state, commit }, id) {
    try {
      await axios.delete(`${api.tasks}/${id}`);
      commit('DELETE_TASK', id);
      commit('SET_SELECTED_TASK', {});
      commit('SET_MODAL', false, { root: true });
    } catch (err) {}
  },
  async delete_reminder ({ state, commit }) {
    try {
      const id = state.selectedReminder._id;
      await axios.delete(`${api.reminders}/${id}`);
      commit('DELETE_REMINDER', id);
      commit('SET_SELECTED_REMINDER', {});
      commit('SET_MODAL', false, { root: true });
    } catch (err) {
      console.log(err);
    }
  },
  async update_goal ({ state, commit }, title) {
    try {
      await axios.put(`${api.profile}/goals`, { title });
      // update today goal commit
      commit('SET_MODAL', false, { root: true });
    } catch (err) {
      console.log(err);
    }
  }
};

export const getters = {
  waitingTasks: (state) => {
    return state.data
      .filter((o) => o.status === 'waiting')
      .reduce((acc, curr) => {
        curr.type === 'meeting' ? acc.push(curr) : acc.unshift(curr);
        return acc;
      }, []);
  },
  inProgressTasks: (state) => {
    let results = state.data.filter((task) => task.status === 'progress');
    if (state.filters.day >= 0) {
      const currentDate = +new Date().getDate();
      const currentMonth = +new Date().getMonth();
      results = results
        .filter(
          (task) => {
            // console.log(task.title, +new Date(task.date).getDay(), +state.filters.day, new Date(task.date).getDate(), +state.filters.date)
            return +new Date(task.date).getDay() === +state.filters.day
              && new Date(task.date).getDate() === +state.filters.date
          }
        )
        // hide tasks with the date greater than 7 days; if is next month calculate de 7 days difference
        .filter((task) => {
          // console.log(+state.filters.day)
          // console.log(task.title, +new Date(task.date).getMonth())
          return +new Date(task.date).getMonth() === currentMonth + 1
            ? +new Date(task.date).getDate() <
            7 - (getDaysInCurrentMonth() - currentDate)
            : +new Date(task.date).getDate() <= currentDate + 7
        });
    }
    // order meetings at the end
    results = results.reduce((acc, curr) => {
      curr.type === 'meeting' ? acc.push(curr) : acc.unshift(curr);
      return acc;
    }, []);
    return results;
  },
  doneTasks: (state) => {
    return state.doneTasks;
  },
  totalDailyCpu: (state) => {
    const tasks = state.data.filter(
      (o) =>
        o.status === 'progress' &&
        (+state.filters.day === 0 ||
          (+new Date(o.date).getDay() === 0
            ? 7
            : +new Date(o.date).getDay()) === +state.filters.day)
    );
    const sum = tasks.reduce((acc, curr) => acc + +curr.energy, 0);
    const average = tasks.length === 0 ? 0 : sum / tasks.length;
    return Math.min(100, Math.max(0, average)).toFixed(2);
  },
  totalEstTime: (state) => (data) => {
    const tasks = data.filter(
      (o) =>
        o.status === 'progress' &&
        (+state.filters.day === 0 ||
          (+new Date(o.date).getDay() === 0
            ? 7
            : +new Date(o.date).getDay()) === +state.filters.day)
    );
    let totalTimeInMinutes = 0;
    for (const task of tasks) {
      totalTimeInMinutes += convertToMinutes(task.timeValue.hours, 'hours');
      totalTimeInMinutes += convertToMinutes(task.timeValue.minutes, 'minutes');
    }
    return `${Math.floor(totalTimeInMinutes / 60)}.${totalTimeInMinutes % 60}`;
  },
  totalAppetite: (state) => (data) => {
    const tasks = data.filter(
      (o) =>
        o.status === 'progress' &&
        (+state.filters.day === 0 ||
          (+new Date(o.date).getDay() === 0
            ? 7
            : +new Date(o.date).getDay()) === +state.filters.day)
    );
    const sum = tasks.reduce((acc, curr) => acc + +curr.appetite, 0);
    return tasks.length === 0 ? 0 : (sum / tasks.length).toFixed(0);
  },
  totalDailyMeetings: (state) => (data) => {
    const tasks = data.filter(
      (o) =>
        o.status === 'progress' &&
        (+state.filters.day === 0 ||
          (+new Date(o.date).getDay() === 0
            ? 7
            : +new Date(o.date).getDay()) === +state.filters.day)
    );
    const meets = tasks.filter((o) => o.type === 'meeting');
    let totalTimeInMinutes = 0;
    for (const task of meets) {
      totalTimeInMinutes += convertToMinutes(task.timeValue.hours, 'hours');
      totalTimeInMinutes += +task.timeValue.minutes;
    }
    return {
      length: meets.length,
      time: `${Math.floor(totalTimeInMinutes / 60)}.${totalTimeInMinutes % 60}`
    };
  }
};

function convertToMinutes (value, unit) {
  if (unit === 'hours') {
    return parseInt(value) * 60; // Convert hours to minutes
  } else if (unit === 'minutes') {
    return parseInt(value); // Keep minutes as is
  }
  return 0; // Handle other time units if needed
}
