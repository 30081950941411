<template>
  <div class="base-search">
    <input
        class="p-l-8"
        type="text"
        :placeholder="`search ${name}`"
        :value="value"
        @input="handleSearch"
    />
    <i
        v-show="searchStr"
        class="m-l-8 pointer"
        @click="handleClearSearch"
    >
      <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
            fill="black"
        />
      </svg>
    </i>
  </div>
</template>

<script setup>
  import {ref} from "vue";

  const props = defineProps({
    name: String,
    value: String
  })
  const emits = defineEmits(['search', 'clear'])
  const searchStr = ref(props.value)

  function handleSearch (ev) {
    searchStr.value = ev.target.value
    emits('search', ev.target.value)
  }
  function handleClearSearch () {
    emits('clear')
  }
</script>

<style lang="scss">
  .base-search {
    @media only screen and (min-width: 0) {
      input {
        width: 154px;
        padding: 4px 8px;
        border: 1px solid lightgray;
        border-radius: 4px;
      }
      i {
        opacity: 0.6;
      }
    }

    @media only screen and (min-width: 1024px) {
      input {
        width: 354px;
      }
    }
  }
</style>