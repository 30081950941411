<template>
  <div class="base-chips" :class="classes">
    <span v-if="label">{{ label }}</span>
    <ul class="chips-grid">
      <li
        class="chip-item pointer"
        :class="{
          'selected-chip': multiSelect
            ? selected.includes(item)
            : selected?.toString() === item?.toString(),
        }"
        v-for="(item, index) in data"
        :key="index"
        @click="handleSelect(item)"
      >
        <span>{{ item }}</span>
      </li>
    </ul>
  </div>
</template>

<script setup>
  const props = defineProps({
    name: String,
    classes: String,
    label: String,
    data: Object,
    selected: [Array, String, Number],
    multiSelect: Boolean
  });

  const emits = defineEmits(['select']);

  function handleSelect (item) {
    emits('select', { name: props.name, value: item });
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .base-chips {
    @media only screen and (min-width: 0) {
      ul {
        margin-top: 8px;
        margin-bottom: 8px;
      }
      li {
        padding: 2px 8px;
        border: 1px solid lightgray;
        border-radius: 4px;
      }
      .selected-chip {
        background-color: $green;
        border-radius: 4px;
        span {
          color: white;
        }
      }
    }
  }
</style>
