import axios from 'axios';
import api from '@/store/modules/api';

export const state = function () {
  return {
    data: []
  };
};

export const mutations = {
  SET_IDEAS_STATE (state, { key, value }) {
    state[key] = value;
  },
  ADD_IDEA (state, data) {
    state.data.push(data);
  },
  UPDATE_IDEA (state, data) {
    const index = state.data.findIndex((o) => o._id === data._id);
    state.data[index] = data;
  },
  REMOVE_IDEA (state, id) {
    const index = state.data.findIndex((o) => o._id === id);
    state.data.splice(index, 1);
  }
};

export const actions = {
  async get_ideas ({ commit }) {
    try {
      const { data } = await axios(api.ideas);
      commit('SET_IDEAS_STATE', { key: 'data', value: data });
    } catch (err) {}
  },
  async create_idea ({ commit }, idea) {
    try {
      const { data } = await axios.post(api.ideas, idea);
      commit('ADD_IDEA', data);
    } catch (err) {}
  },
  async update_idea ({ commit }, idea) {
    try {
      const url = `${api.ideas}/${idea._id}`;
      const { data } = await axios.put(url, idea);
      commit('UPDATE_IDEA', idea);
    } catch (err) {}
  },
  async delete_idea ({ commit }, id) {
    try {
      const url = `${api.ideas}/${id}`;
      const { data } = await axios.delete(url);
      commit('REMOVE_IDEA', id);
    } catch (err) {}
  }
};
