<template>
  <img
    v-if="isEdit"
    class="edit-icon absolute pointer"
    src="../assets/edit-icon.svg"
    alt="edit"
    :cy="`edit-${name}`"
    @click="handleClick"
  />
</template>

<script setup>
  import { computed } from 'vue';
  import { useStore } from 'vuex';

  const { state, dispatch, commit } = useStore();
  const props = defineProps({
    name: String
  });
  const emits = defineEmits(['click']);
  const isEdit = computed(() => state.projects.isEdit);

  function handleClick (ev) {
    emits('click', { name: props.name, ev });
  }
</script>

<style lang="scss">
  .edit-icon {
    @media only screen and (min-width: 0) {
      left: -40px;
    }
  }
</style>
